import React from "react";
import { makeStyles } from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import MailIcon from "@material-ui/icons/Mail";
import Business from "@material-ui/icons/Business";
import Phone from "@material-ui/icons/Phone";
import { Grid } from "@material-ui/core";

import { Card } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  Card: {
    backgroundColor: "#c6b3f2",
  },
  Footer: {
    textAlign: "center",
    color: "#fcfcfc",
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.Card}>
        <Card.Footer className={classes.Footer}>
          <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Business />&nbsp;
              <a href="https://goo.gl/maps/Zy2etq1P1cLfjKHr9">
                Columbus, OH
              </a>{" "}
              &nbsp;&nbsp;
              <MailIcon />&nbsp;
              <a href="contactus@cloudinityinc.com">
                info@creativecustomlayers.com  
              </a>
              &nbsp;&nbsp;
             
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <CopyrightIcon />
          All Rights Reserved - Creative Custom Layers
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Footer;
